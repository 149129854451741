import '../css/app.scss';

// jQuery
const $ = require('jquery');
global.$ = global.jQuery = $;

// import 'jquery'

// loads the Bootstrap jQuery plugins
import 'bootstrap/js/dist/alert.js';
import 'bootstrap/js/dist/collapse.js';
import 'bootstrap/js/dist/dropdown.js';
import 'bootstrap/js/dist/modal.js';
import 'bootstrap/js/dist/tab.js';
import 'bootstrap/js/dist/popover.js';

// import 'datatable';
import 'jszip';
import 'pdfmake';
import 'datatables.net-bs4';
import 'datatables.net-buttons-bs4';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
// import 'datatables.net-buttons';

//loads flash notification listener
import './global/flash_notification.js'

//import js et css menu droit
import './global/menu_droit.js'

//import js bibliotheque
import './bibliotheque/bibliotheque.js'

import './select2MultiCheckboxes/s2mcb.js'

//logger d'exeption. catch les erreur javascript et les envoie par mail n'est pas fonctionnel dans l'application
//import LoggerException from './app/logger_exception/LoggerException.js'

// initiatlisation et activation javascript des flash message
$('#flash-messages').flashNotification('init'); 
//todo: voir son fonctionnement
//const loggerException = new LoggerException();
